/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */

import React, { useState, useEffect, useRef } from 'react';
import { useScreenMatchOnMobile } from '@hooks/useScreenMatchOnMobile';
import styles from './CovidMap.module.css';
import { COVID_19_EMBEDED_MAP_URI } from '../../constants/externalUris';
import { classNames } from '../../utils/classNames';

export default function CovidMap(): React.ReactElement {
  const isMobile = useScreenMatchOnMobile();

  const [frameActive, setFrameActive] = useState(!isMobile);

  const [isClient, setIsClient] = useState(false);

  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => setFrameActive(!isMobile), [isMobile]);

  // TODO: potentially weak point
  // BCDTRAAP-684
  // is called only in browser to alter the static markup from server
  useEffect(() => {
    setIsClient(true);

    if (iframeRef && iframeRef.current) {
      iframeRef.current.click();

      if (!navigator.userAgent.includes('iPhone')) {
        iframeRef.current.setAttribute('scrolling', 'no');
      }
    }
  }, []);

  return (
    <div
      className={styles.container}
      onMouseLeave={() => isMobile && setFrameActive(false)}
      onClick={() => isMobile && setFrameActive(true)}
    >
      <iframe
        ref={iframeRef}
        className={classNames([
          styles.mapIframe,
          !frameActive && isClient ? styles.disablePointerEvents : '',
        ])}
        src={COVID_19_EMBEDED_MAP_URI}
        title="COVID-19 maps"
      />
    </div>
  );
}
