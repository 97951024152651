import React from 'react';
import { CovidMap } from '@components/CovidMap';
import { MainMenu } from '@components/MainMenu';

import { IPageProps } from '../types';

const CovidMapPage = (props: IPageProps): React.ReactElement => {
  const {
    uri,
    navigate,
    location: { pathname },
  } = props;

  return (
    <MainMenu uri={uri} navigate={navigate} staticUri={pathname}>
      <CovidMap />
    </MainMenu>
  );
};

export default CovidMapPage;
